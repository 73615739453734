var exports = {};
exports = [{
  name: "aqua",
  hex: "#00FFFF"
}, {
  name: "aliceblue",
  hex: "#F0F8FF"
}, {
  name: "antiquewhite",
  hex: "#FAEBD7"
}, {
  name: "black",
  hex: "#000000"
}, {
  name: "blue",
  hex: "#0000FF"
}, {
  name: "cyan",
  hex: "#00FFFF"
}, {
  name: "darkblue",
  hex: "#00008B"
}, {
  name: "darkcyan",
  hex: "#008B8B"
}, {
  name: "darkgreen",
  hex: "#006400"
}, {
  name: "darkturquoise",
  hex: "#00CED1"
}, {
  name: "deepskyblue",
  hex: "#00BFFF"
}, {
  name: "green",
  hex: "#008000"
}, {
  name: "lime",
  hex: "#00FF00"
}, {
  name: "mediumblue",
  hex: "#0000CD"
}, {
  name: "mediumspringgreen",
  hex: "#00FA9A"
}, {
  name: "navy",
  hex: "#000080"
}, {
  name: "springgreen",
  hex: "#00FF7F"
}, {
  name: "teal",
  hex: "#008080"
}, {
  name: "midnightblue",
  hex: "#191970"
}, {
  name: "dodgerblue",
  hex: "#1E90FF"
}, {
  name: "lightseagreen",
  hex: "#20B2AA"
}, {
  name: "forestgreen",
  hex: "#228B22"
}, {
  name: "seagreen",
  hex: "#2E8B57"
}, {
  name: "darkslategray",
  hex: "#2F4F4F"
}, {
  name: "darkslategrey",
  hex: "#2F4F4F"
}, {
  name: "limegreen",
  hex: "#32CD32"
}, {
  name: "mediumseagreen",
  hex: "#3CB371"
}, {
  name: "turquoise",
  hex: "#40E0D0"
}, {
  name: "royalblue",
  hex: "#4169E1"
}, {
  name: "steelblue",
  hex: "#4682B4"
}, {
  name: "darkslateblue",
  hex: "#483D8B"
}, {
  name: "mediumturquoise",
  hex: "#48D1CC"
}, {
  name: "indigo",
  hex: "#4B0082"
}, {
  name: "darkolivegreen",
  hex: "#556B2F"
}, {
  name: "cadetblue",
  hex: "#5F9EA0"
}, {
  name: "cornflowerblue",
  hex: "#6495ED"
}, {
  name: "mediumaquamarine",
  hex: "#66CDAA"
}, {
  name: "dimgray",
  hex: "#696969"
}, {
  name: "dimgrey",
  hex: "#696969"
}, {
  name: "slateblue",
  hex: "#6A5ACD"
}, {
  name: "olivedrab",
  hex: "#6B8E23"
}, {
  name: "slategray",
  hex: "#708090"
}, {
  name: "slategrey",
  hex: "#708090"
}, {
  name: "lightslategray",
  hex: "#778899"
}, {
  name: "lightslategrey",
  hex: "#778899"
}, {
  name: "mediumslateblue",
  hex: "#7B68EE"
}, {
  name: "lawngreen",
  hex: "#7CFC00"
}, {
  name: "aquamarine",
  hex: "#7FFFD4"
}, {
  name: "chartreuse",
  hex: "#7FFF00"
}, {
  name: "gray",
  hex: "#808080"
}, {
  name: "grey",
  hex: "#808080"
}, {
  name: "maroon",
  hex: "#800000"
}, {
  name: "olive",
  hex: "#808000"
}, {
  name: "purple",
  hex: "#800080"
}, {
  name: "lightskyblue",
  hex: "#87CEFA"
}, {
  name: "skyblue",
  hex: "#87CEEB"
}, {
  name: "blueviolet",
  hex: "#8A2BE2"
}, {
  name: "darkmagenta",
  hex: "#8B008B"
}, {
  name: "darkred",
  hex: "#8B0000"
}, {
  name: "saddlebrown",
  hex: "#8B4513"
}, {
  name: "darkseagreen",
  hex: "#8FBC8F"
}, {
  name: "lightgreen",
  hex: "#90EE90"
}, {
  name: "mediumpurple",
  hex: "#9370DB"
}, {
  name: "darkviolet",
  hex: "#9400D3"
}, {
  name: "palegreen",
  hex: "#98FB98"
}, {
  name: "darkorchid",
  hex: "#9932CC"
}, {
  name: "yellowgreen",
  hex: "#9ACD32"
}, {
  name: "sienna",
  hex: "#A0522D"
}, {
  name: "brown",
  hex: "#A52A2A"
}, {
  name: "darkgray",
  hex: "#A9A9A9"
}, {
  name: "darkgrey",
  hex: "#A9A9A9"
}, {
  name: "greenyellow",
  hex: "#ADFF2F"
}, {
  name: "lightblue",
  hex: "#ADD8E6"
}, {
  name: "paleturquoise",
  hex: "#AFEEEE"
}, {
  name: "lightsteelblue",
  hex: "#B0C4DE"
}, {
  name: "powderblue",
  hex: "#B0E0E6"
}, {
  name: "firebrick",
  hex: "#B22222"
}, {
  name: "darkgoldenrod",
  hex: "#B8860B"
}, {
  name: "mediumorchid",
  hex: "#BA55D3"
}, {
  name: "rosybrown",
  hex: "#BC8F8F"
}, {
  name: "darkkhaki",
  hex: "#BDB76B"
}, {
  name: "silver",
  hex: "#C0C0C0"
}, {
  name: "mediumvioletred",
  hex: "#C71585"
}, {
  name: "indianred",
  hex: "#CD5C5C"
}, {
  name: "peru",
  hex: "#CD853F"
}, {
  name: "chocolate",
  hex: "#D2691E"
}, {
  name: "tan",
  hex: "#D2B48C"
}, {
  name: "lightgray",
  hex: "#D3D3D3"
}, {
  name: "lightgrey",
  hex: "#D3D3D3"
}, {
  name: "thistle",
  hex: "#D8BFD8"
}, {
  name: "goldenrod",
  hex: "#DAA520"
}, {
  name: "orchid",
  hex: "#DA70D6"
}, {
  name: "palevioletred",
  hex: "#DB7093"
}, {
  name: "crimson",
  hex: "#DC143C"
}, {
  name: "gainsboro",
  hex: "#DCDCDC"
}, {
  name: "plum",
  hex: "#DDA0DD"
}, {
  name: "burlywood",
  hex: "#DEB887"
}, {
  name: "lightcyan",
  hex: "#E0FFFF"
}, {
  name: "lavender",
  hex: "#E6E6FA"
}, {
  name: "darksalmon",
  hex: "#E9967A"
}, {
  name: "palegoldenrod",
  hex: "#EEE8AA"
}, {
  name: "violet",
  hex: "#EE82EE"
}, {
  name: "azure",
  hex: "#F0FFFF"
}, {
  name: "honeydew",
  hex: "#F0FFF0"
}, {
  name: "khaki",
  hex: "#F0E68C"
}, {
  name: "lightcoral",
  hex: "#F08080"
}, {
  name: "sandybrown",
  hex: "#F4A460"
}, {
  name: "beige",
  hex: "#F5F5DC"
}, {
  name: "mintcream",
  hex: "#F5FFFA"
}, {
  name: "wheat",
  hex: "#F5DEB3"
}, {
  name: "whitesmoke",
  hex: "#F5F5F5"
}, {
  name: "ghostwhite",
  hex: "#F8F8FF"
}, {
  name: "lightgoldenrodyellow",
  hex: "#FAFAD2"
}, {
  name: "linen",
  hex: "#FAF0E6"
}, {
  name: "salmon",
  hex: "#FA8072"
}, {
  name: "oldlace",
  hex: "#FDF5E6"
}, {
  name: "bisque",
  hex: "#FFE4C4"
}, {
  name: "blanchedalmond",
  hex: "#FFEBCD"
}, {
  name: "coral",
  hex: "#FF7F50"
}, {
  name: "cornsilk",
  hex: "#FFF8DC"
}, {
  name: "darkorange",
  hex: "#FF8C00"
}, {
  name: "deeppink",
  hex: "#FF1493"
}, {
  name: "floralwhite",
  hex: "#FFFAF0"
}, {
  name: "fuchsia",
  hex: "#FF00FF"
}, {
  name: "gold",
  hex: "#FFD700"
}, {
  name: "hotpink",
  hex: "#FF69B4"
}, {
  name: "ivory",
  hex: "#FFFFF0"
}, {
  name: "lavenderblush",
  hex: "#FFF0F5"
}, {
  name: "lemonchiffon",
  hex: "#FFFACD"
}, {
  name: "lightpink",
  hex: "#FFB6C1"
}, {
  name: "lightsalmon",
  hex: "#FFA07A"
}, {
  name: "lightyellow",
  hex: "#FFFFE0"
}, {
  name: "magenta",
  hex: "#FF00FF"
}, {
  name: "mistyrose",
  hex: "#FFE4E1"
}, {
  name: "moccasin",
  hex: "#FFE4B5"
}, {
  name: "navajowhite",
  hex: "#FFDEAD"
}, {
  name: "orange",
  hex: "#FFA500"
}, {
  name: "orangered",
  hex: "#FF4500"
}, {
  name: "papayawhip",
  hex: "#FFEFD5"
}, {
  name: "peachpuff",
  hex: "#FFDAB9"
}, {
  name: "pink",
  hex: "#FFC0CB"
}, {
  name: "red",
  hex: "#FF0000"
}, {
  name: "seashell",
  hex: "#FFF5EE"
}, {
  name: "snow",
  hex: "#FFFAFA"
}, {
  name: "tomato",
  hex: "#FF6347"
}, {
  name: "white",
  hex: "#FFFFFF"
}, {
  name: "yellow",
  hex: "#FFFF00"
}];
export default exports;