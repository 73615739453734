var exports = {};
exports = [{
  name: "indigo",
  hex: "#4b0082"
}, {
  name: "gold",
  hex: "#ffd700"
}, {
  name: "hotpink",
  hex: "#ff69b4"
}, {
  name: "firebrick",
  hex: "#b22222"
}, {
  name: "indianred",
  hex: "#cd5c5c"
}, {
  name: "yellow",
  hex: "#ffff00"
}, {
  name: "mistyrose",
  hex: "#ffe4e1"
}, {
  name: "darkolivegreen",
  hex: "#556b2f"
}, {
  name: "olive",
  hex: "#808000"
}, {
  name: "darkseagreen",
  hex: "#8fbc8f"
}, {
  name: "pink",
  hex: "#ffc0cb"
}, {
  name: "tomato",
  hex: "#ff6347"
}, {
  name: "lightcoral",
  hex: "#f08080"
}, {
  name: "orangered",
  hex: "#ff4500"
}, {
  name: "navajowhite",
  hex: "#ffdead"
}, {
  name: "lime",
  hex: "#00ff00"
}, {
  name: "palegreen",
  hex: "#98fb98"
}, {
  name: "darkslategrey",
  hex: "#2f4f4f"
}, {
  name: "greenyellow",
  hex: "#adff2f"
}, {
  name: "burlywood",
  hex: "#deb887"
}, {
  name: "seashell",
  hex: "#fff5ee"
}, {
  name: "mediumspringgreen",
  hex: "#00fa9a"
}, {
  name: "fuchsia",
  hex: "#ff00ff"
}, {
  name: "papayawhip",
  hex: "#ffefd5"
}, {
  name: "blanchedalmond",
  hex: "#ffebcd"
}, {
  name: "chartreuse",
  hex: "#7fff00"
}, {
  name: "dimgray",
  hex: "#696969"
}, {
  name: "black",
  hex: "#000000"
}, {
  name: "peachpuff",
  hex: "#ffdab9"
}, {
  name: "springgreen",
  hex: "#00ff7f"
}, {
  name: "aquamarine",
  hex: "#7fffd4"
}, {
  name: "white",
  hex: "#ffffff"
}, {
  name: "orange",
  hex: "#ffa500"
}, {
  name: "lightsalmon",
  hex: "#ffa07a"
}, {
  name: "darkslategray",
  hex: "#2f4f4f"
}, {
  name: "brown",
  hex: "#a52a2a"
}, {
  name: "ivory",
  hex: "#fffff0"
}, {
  name: "dodgerblue",
  hex: "#1e90ff"
}, {
  name: "peru",
  hex: "#cd853f"
}, {
  name: "lawngreen",
  hex: "#7cfc00"
}, {
  name: "chocolate",
  hex: "#d2691e"
}, {
  name: "crimson",
  hex: "#dc143c"
}, {
  name: "forestgreen",
  hex: "#228b22"
}, {
  name: "darkgrey",
  hex: "#a9a9a9"
}, {
  name: "lightseagreen",
  hex: "#20b2aa"
}, {
  name: "cyan",
  hex: "#00ffff"
}, {
  name: "mintcream",
  hex: "#f5fffa"
}, {
  name: "silver",
  hex: "#c0c0c0"
}, {
  name: "antiquewhite",
  hex: "#faebd7"
}, {
  name: "mediumorchid",
  hex: "#ba55d3"
}, {
  name: "skyblue",
  hex: "#87ceeb"
}, {
  name: "gray",
  hex: "#808080"
}, {
  name: "darkturquoise",
  hex: "#00ced1"
}, {
  name: "goldenrod",
  hex: "#daa520"
}, {
  name: "darkgreen",
  hex: "#006400"
}, {
  name: "floralwhite",
  hex: "#fffaf0"
}, {
  name: "darkviolet",
  hex: "#9400d3"
}, {
  name: "darkgray",
  hex: "#a9a9a9"
}, {
  name: "moccasin",
  hex: "#ffe4b5"
}, {
  name: "saddlebrown",
  hex: "#8b4513"
}, {
  name: "grey",
  hex: "#808080"
}, {
  name: "darkslateblue",
  hex: "#483d8b"
}, {
  name: "lightskyblue",
  hex: "#87cefa"
}, {
  name: "lightpink",
  hex: "#ffb6c1"
}, {
  name: "mediumvioletred",
  hex: "#c71585"
}, {
  name: "slategrey",
  hex: "#708090"
}, {
  name: "red",
  hex: "#ff0000"
}, {
  name: "deeppink",
  hex: "#ff1493"
}, {
  name: "limegreen",
  hex: "#32cd32"
}, {
  name: "darkmagenta",
  hex: "#8b008b"
}, {
  name: "palegoldenrod",
  hex: "#eee8aa"
}, {
  name: "plum",
  hex: "#dda0dd"
}, {
  name: "turquoise",
  hex: "#40e0d0"
}, {
  name: "lightgrey",
  hex: "#d3d3d3"
}, {
  name: "lightgoldenrodyellow",
  hex: "#fafad2"
}, {
  name: "darkgoldenrod",
  hex: "#b8860b"
}, {
  name: "lavender",
  hex: "#e6e6fa"
}, {
  name: "maroon",
  hex: "#800000"
}, {
  name: "yellowgreen",
  hex: "#9acd32"
}, {
  name: "sandybrown",
  hex: "#f4a460"
}, {
  name: "thistle",
  hex: "#d8bfd8"
}, {
  name: "violet",
  hex: "#ee82ee"
}, {
  name: "navy",
  hex: "#000080"
}, {
  name: "magenta",
  hex: "#ff00ff"
}, {
  name: "dimgrey",
  hex: "#696969"
}, {
  name: "tan",
  hex: "#d2b48c"
}, {
  name: "rosybrown",
  hex: "#bc8f8f"
}, {
  name: "olivedrab",
  hex: "#6b8e23"
}, {
  name: "blue",
  hex: "#0000ff"
}, {
  name: "lightblue",
  hex: "#add8e6"
}, {
  name: "ghostwhite",
  hex: "#f8f8ff"
}, {
  name: "honeydew",
  hex: "#f0fff0"
}, {
  name: "cornflowerblue",
  hex: "#6495ed"
}, {
  name: "slateblue",
  hex: "#6a5acd"
}, {
  name: "linen",
  hex: "#faf0e6"
}, {
  name: "darkblue",
  hex: "#00008b"
}, {
  name: "powderblue",
  hex: "#b0e0e6"
}, {
  name: "seagreen",
  hex: "#2e8b57"
}, {
  name: "darkkhaki",
  hex: "#bdb76b"
}, {
  name: "snow",
  hex: "#fffafa"
}, {
  name: "sienna",
  hex: "#a0522d"
}, {
  name: "mediumblue",
  hex: "#0000cd"
}, {
  name: "royalblue",
  hex: "#4169e1"
}, {
  name: "lightcyan",
  hex: "#e0ffff"
}, {
  name: "green",
  hex: "#008000"
}, {
  name: "mediumpurple",
  hex: "#9370db"
}, {
  name: "midnightblue",
  hex: "#191970"
}, {
  name: "cornsilk",
  hex: "#fff8dc"
}, {
  name: "paleturquoise",
  hex: "#afeeee"
}, {
  name: "bisque",
  hex: "#ffe4c4"
}, {
  name: "slategray",
  hex: "#708090"
}, {
  name: "darkcyan",
  hex: "#008b8b"
}, {
  name: "khaki",
  hex: "#f0e68c"
}, {
  name: "wheat",
  hex: "#f5deb3"
}, {
  name: "teal",
  hex: "#008080"
}, {
  name: "darkorchid",
  hex: "#9932cc"
}, {
  name: "deepskyblue",
  hex: "#00bfff"
}, {
  name: "salmon",
  hex: "#fa8072"
}, {
  name: "darkred",
  hex: "#8b0000"
}, {
  name: "steelblue",
  hex: "#4682b4"
}, {
  name: "palevioletred",
  hex: "#db7093"
}, {
  name: "lightslategray",
  hex: "#778899"
}, {
  name: "aliceblue",
  hex: "#f0f8ff"
}, {
  name: "lightslategrey",
  hex: "#778899"
}, {
  name: "lightgreen",
  hex: "#90ee90"
}, {
  name: "orchid",
  hex: "#da70d6"
}, {
  name: "gainsboro",
  hex: "#dcdcdc"
}, {
  name: "mediumseagreen",
  hex: "#3cb371"
}, {
  name: "lightgray",
  hex: "#d3d3d3"
}, {
  name: "mediumturquoise",
  hex: "#48d1cc"
}, {
  name: "lemonchiffon",
  hex: "#fffacd"
}, {
  name: "cadetblue",
  hex: "#5f9ea0"
}, {
  name: "lightyellow",
  hex: "#ffffe0"
}, {
  name: "lavenderblush",
  hex: "#fff0f5"
}, {
  name: "coral",
  hex: "#ff7f50"
}, {
  name: "purple",
  hex: "#800080"
}, {
  name: "aqua",
  hex: "#00ffff"
}, {
  name: "whitesmoke",
  hex: "#f5f5f5"
}, {
  name: "mediumslateblue",
  hex: "#7b68ee"
}, {
  name: "darkorange",
  hex: "#ff8c00"
}, {
  name: "mediumaquamarine",
  hex: "#66cdaa"
}, {
  name: "darksalmon",
  hex: "#e9967a"
}, {
  name: "beige",
  hex: "#f5f5dc"
}, {
  name: "blueviolet",
  hex: "#8a2be2"
}, {
  name: "azure",
  hex: "#f0ffff"
}, {
  name: "lightsteelblue",
  hex: "#b0c4de"
}, {
  name: "oldlace",
  hex: "#fdf5e6"
}];
export default exports;