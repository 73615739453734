import _chromaJs from "chroma-js";
import _es6WeakMap from "es6-weak-map";
import _basic from "./lib/colors/basic";
import _html from "./lib/colors/html";
import _ntc from "./lib/colors/ntc";
import _pantone from "./lib/colors/pantone";
import _roygbiv from "./lib/colors/roygbiv";
import _x from "./lib/colors/x11";
var exports = {};
var chroma = _chromaJs;
var WeakMap = _es6WeakMap; // These `require` statements are all explicit
// to keep the browserify build from breaking

var lists = {
  basic: _basic,
  html: _html,
  ntc: _ntc,
  pantone: _pantone,
  roygbiv: _roygbiv,
  x11: _x
};
var cache = new WeakMap();

var namer = exports = function (color, options) {
  options = options || {};
  var cacheKey = {
    color,
    options
  };

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  var deltaE = String(options.distance).toLowerCase() === "deltae";
  color = chroma(color);
  var results = {};

  for (var key in lists) {
    if (options.pick && options.pick.indexOf(key) === -1) {
      continue;
    }

    if (options.omit && options.omit.indexOf(key) !== -1) {
      continue;
    }

    results[key] = lists[key].map(function (name) {
      name.distance = deltaE ? chroma.deltaE(color, chroma(name.hex)) : chroma.distance(color, chroma(name.hex));
      return name;
    }).sort(function (a, b) {
      return a.distance - b.distance;
    });
  }

  cache.set(cacheKey, results);
  return results;
};

namer.chroma = chroma;
namer.lists = lists;
export default exports;